import React from "react"
import { graphql } from "gatsby"
import * as styles from "./type-page.module.scss"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import sanitizeHtml from "sanitize-html"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import ShareButtons from "../../components/type-page/share-buttons/share-buttons"
import NewsBlocks from "../../components/type-page/news-blocks/news-blocks"
import SideBlocks from "../../components/type-page/side-blocks/side-blocks"
import * as config from "../../../config"
import * as menuData from "../../components/type-page/breadcrumb/breadcrumb"
import {
  NewsListFieldsFragment,
  TransportTitleListFieldsFragment,
  TransportTitleSearchModuleConfigFieldsFragment,
  TypePageQuery,
} from "../../../graphql-types"
import RichDatoCmsContent from "../../components/rich-datocms-content/rich-datocms-content"
import { isNewsPublished } from "../../components/all-news/all-news"
import { useDetectScroll, useFormatLocale } from "../../hooks"
import pageViewEvent from "../../gtm/page-view-event"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"

type RenderProps = {
  data: TypePageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const TypePage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const twitterDisplay: boolean = data.datoCmsGlobalConfiguration.twitterDisplay
  const facebookDisplay: boolean = data.datoCmsGlobalConfiguration.fbDisplay
  const page = data.datoCmsPage

  let sideBlocks = page.sideBlocks
  const news: NewsListFieldsFragment[] = data.allDatoCmsNews.nodes
  const rawTitle = sanitizeHtml(page.title, {
    allowedTags: [],
    allowedAttributes: {},
  })

  const lastThreeNews = data.allDatoCmsNews.nodes
    .filter((newsElement: NewsListFieldsFragment) => isNewsPublished(newsElement))
    .slice(0, 3)
  const transportTitles: TransportTitleListFieldsFragment[] = data.allDatoCmsTransportTitle.nodes
  const transportTitleSearchModuleConfig: TransportTitleSearchModuleConfigFieldsFragment = data.datoCmsTitleAndPrice
  const lastThreeNewsLabel: string = data.datoCmsGlobalConfiguration.lastNewsLabel

  if (!sideBlocks || sideBlocks.length == 0) {
    sideBlocks = data.datoCmsGlobalConfiguration.sideBlocks
  }

  useDetectScroll()

  pageViewEvent(config.locale, data.site.siteMetadata.siteUrl, menuData, rawTitle)

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={useFormatLocale(pageContext.locale)}
      defaultLocale={useFormatLocale(pageContext.defaultLocale)}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
      mySubscriptionsPage={data.datoCmsMySubscriptionsPage}
    >
      <div className={styles.typePage}>
        <TitleBanner title={page.title} />
        <Breadcrumb
          menuData={data.datoCmsMenu}
          pageTitle={page.title}
          pageSlug={page.slug}
          locale={useFormatLocale(pageContext.locale)}
          defaultLocale={useFormatLocale(pageContext.defaultLocale)}
        />
        <div className={styles.typePageContent}>
          <div className={styles.content} style={{ width: data.datoCmsPage.hideRightSidebar ? "100%" : "75%" }}>
            <RichDatoCmsContent
              data={page.content}
              pageTitle={page.title}
              allNewsData={news}
              allNewsModuleConfiguration={data.datoCmsGlobalConfiguration}
              transportTitleSearchData={transportTitles}
              transportTitleSearchModuleConfiguration={transportTitleSearchModuleConfig}
              schedulesSearchData={data.allDatoCmsLine.nodes}
              schedulesSearchModuleConfiguration={data.datoCmsSchedulesSearchModule}
              ticketOfficeSearchData={data.allDatoCmsTicketOffice.nodes}
              ticketOfficeSearchModuleConfiguration={data.datoCmsTicketOfficeModule}
              mapboxToken={data.datoCmsGlobalConfiguration.mapboxToken}
              itineraryModuleConfiguration={data.datoCmsItineraryModuleConfiguration}
              nextDeparturesModuleConfiguration={data.datoCmsNextDeparturesModuleConfiguration}
              infoTrafficData={data.allDatoCmsInfoTraffic.nodes}
              customFormsConfiguration={data.datoCmsFormsConfiguration}
              formCaptchaKey={data.datoCmsGlobalConfiguration.formCaptchaKey}
              menuData={data.datoCmsMenu}
              lostObjectsFormLines={data.allDatoCmsLine.nodes}
              lostObjectsFormConfiguration={data.datoCmsLostObjectsFormConfiguration}
              smsAlertFormConfiguration={data.datoCmsSmsAlertInfoTrafficFormConfiguration}
              lePiloteInfoTrafficLinesIconsData={data.allDatoCmsAsset.nodes}
            />
          </div>
          {!data.datoCmsPage.hideRightSidebar && (
            <div className={styles.rightSidebar}>
              <ShareButtons facebookDisplay={facebookDisplay} twitterDisplay={twitterDisplay} />
              <NewsBlocks
                news={lastThreeNews}
                label={lastThreeNewsLabel}
                locale={useFormatLocale(pageContext.locale)}
                defaultLocale={useFormatLocale(pageContext.defaultLocale)}
              />
              <SideBlocks
                blocks={sideBlocks}
                locale={useFormatLocale(pageContext.locale)}
                defaultLocale={useFormatLocale(pageContext.defaultLocale)}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  const page = data.datoCmsPage
  const rawTitle = sanitizeHtml(page.title, {
    allowedTags: [],
    allowedAttributes: {},
  })

  return (
    <>
      <SEO title={rawTitle} meta={page.seoMetaTags?.tags} />
      <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
    </>
  )
}

export const pageQuery = graphql`
  query TypePage($locale: String!, $id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    datoCmsMySubscriptionsPage {
      ...MySubscriptionsPageFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }) {
      nodes {
        ...NewsListFields
      }
    }

    datoCmsGlobalConfiguration(locale: $locale) {
      fbDisplay
      twitterDisplay
      sideBlocks {
        ...SideBlockFields
      }
      ...TicketOfficeSearchMapConfigurationFields
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
      apiToken
      apiUrl
      apiUrlGetLinesEndpoint
      apiUrlUserRegistrationEndpoint
      formCaptchaKey
    }

    datoCmsPage(locale: $locale, id: { eq: $id }) {
      ...TypePageFields
    }

    allDatoCmsLine(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...LinesListFields
        ...LostObjectsFormLineFields
      }
    }

    datoCmsSchedulesSearchModule {
      ...SchedulesSearchModuleFields
    }

    allDatoCmsTransportTitle(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...TransportTitleListFields
      }
    }

    datoCmsTitleAndPrice {
      ...TransportTitleSearchModuleConfigFields
    }

    allDatoCmsTicketOffice(locale: $locale) {
      nodes {
        ...TicketOfficeSearchFields
      }
    }

    datoCmsTicketOfficeModule {
      ...TicketOfficeSearchModuleConfigurationFields
    }

    datoCmsItineraryModuleConfiguration {
      ...ItineraryModuleConfigurationFields
    }

    allDatoCmsInfoTraffic(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...InfoTrafficFields
      }
    }

    allDatoCmsAsset(filter: { isImage: { eq: true }, basename: { regex: "/^picto-/" } }) {
      nodes {
        ...LePiloteInfoTrafficLinesIconsFields
      }
    }

    datoCmsNextDeparturesModuleConfiguration {
      ...NextDeparturesModuleConfigurationFields
    }

    datoCmsFormsConfiguration {
      ...CustomFormsConfigurationFields
    }

    datoCmsLostObjectsFormConfiguration {
      ...LostObjectsFormConfigurationFields
    }

    datoCmsSmsAlertInfoTrafficFormConfiguration {
      ...SmsAlertInfoTrafficFormConfigurationFields
    }
  }
`

export const pageFragment = graphql`
  fragment TypePageFields on DatoCmsPage {
    id
    slug
    title
    seoMetaTags {
      tags
    }
    hideRightSidebar
    sideBlocks {
      ...SideBlockFields
    }
    content {
      __typename

      ... on DatoCmsSimpleText {
        ...SimpleTextFields
      }

      ... on DatoCmsCustomText {
        ...CustomTextFields
      }

      ... on DatoCmsTextWithImage {
        ...TextWithImageFields
      }

      ... on DatoCmsTextWithVideo {
        ...TextWithVideoFields
      }

      ... on DatoCmsHighlight {
        ...HighlightFields
      }

      ... on DatoCmsVideoOnly {
        ...VideoOnlyFields
      }

      ... on DatoCmsEmbeddedIframe {
        ...EmbeddedIframeFields
      }

      ... on DatoCmsSchedulesSearch {
        ...SchedulesSearchFields
      }

      ... on DatoCmsTransportTitleSearchModule {
        ...TransportTitleSearchFields
      }

      ... on DatoCmsAllNews {
        ...AllNewsFields
      }

      ... on DatoCmsTicketOfficeSearchModule {
        ...TicketOfficeSearchModuleFields
      }

      ... on DatoCmsItineraryModule {
        ...ItineraryModuleFields
      }

      ... on DatoCmsInfoTrafficModule {
        ...InfoTrafficModuleFields
      }

      ... on DatoCmsNextDeparturesModule {
        ...NextDeparturesModuleFields
      }

      ... on DatoCmsCustomFormModule {
        ...CustomFormModuleFields
      }

      ... on DatoCmsWebsiteMapModule {
        ...WebsiteMapModuleFields
      }

      ... on DatoCmsLostObjectsForm {
        ...LostObjectsFormFields
      }

      ... on DatoCmsSmsAlertForm {
        ...SmsAlertFormFields
      }

      ... on DatoCmsLePiloteInfoTrafficModule {
        ...LePiloteInfoTrafficModuleFields
      }

      ... on DatoCmsTitleBlock {
        ...TitleBlockFields
      }

      ... on DatoCmsLePiloteNextTripsSearchModule {
        ...LePiloteNextTripsSearchModuleFields
      }

      ... on DatoCmsLePiloteItineraryModule {
        ...LePiloteItineraryModuleFields
      }

      ... on DatoCmsAroundMeActigraphModule {
        ...AroundMeActigraphModuleFields
      }

      ... on DatoCmsOskarPaymentModule {
        ...OskarPaymentModuleFields
      }

      ... on DatoCmsInfoTrafficDetailModule {
        ...InfoTrafficDetailModuleFields
      }

      ... on DatoCmsTerDakarItinerarySearchModule {
        ...TerDakarItinerarySearchModuleFields
      }

      ... on DatoCmsButton {
        ...ButtonFields
      }

      ... on DatoCmsVianavigoItineraryModule {
        ...VianavigoItineraryModuleFields
      }
    }
  }
`

export default TypePage
